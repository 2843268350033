html, body {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(255, 255, 255);
  color: rgb(50, 50, 50);
  height: 100%;
  font-size: 1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (max-device-width: 767px) {
  html, body {
    /* position: relative; */
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    width: 100%;
  }
}

.App_app__WCVYk {
  position: relative;
}

.App_contentWrapper__rzxoG {
  position: relative;
  width: 100%;
  height: 100%;
  /* filter: blur(4px); */
}

.App_overlay__3SzL3 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}

.Menu_menu__Qxmyw {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.Menu_menuAnim__3qvEV {
  transition: -webkit-transform 0.5s ease-out;
  transition: transform 0.5s ease-out;
  transition: transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
}

.Menu_menuWrapper__1Zugg {
  position: fixed;
  /* top: -20px; */
  /* left:10%; */
  display: flex;
  flex-direction: column;
}

.Menu_menuItems__2d84f {
  /* margin-top: 32px; */
  /* margin-top: 2rem; */
  margin-left: 4px;
  /* margin-top: 4px; */
}

.Menu_logo__2WCG9 {
  /* font-size: 140px; */
  font-weight: normal;
  /* margin-right: 5px; */
  margin-left: 4px;
  margin-top: 4px;
}

/* @media only screen and (max-device-width: 767px) {
  .socialmenu{
    display: none;
  }
} */

@media only screen and (min-device-width: 767px) {
  .Menu_mobileMenu__2ynRs {
    display: none;
  }
}

@media only screen and (max-device-width: 767px) {
  .Menu_menu__Qxmyw {
    flex-direction: column;
  }

  .Menu_mobileMenu__2ynRs {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 500;
    /* height: 100% */
    /* background-color: rgb(220, 220, 220); */
  }

  .Menu_menuWrapper__1Zugg {
    display: none;
  }

  .Menu_social__3jcWX{
    display: none;
  }

  .Menu_lang__3gYRw {
    display: none;
  }
}

.MenuItems_MenuItems__36Zty {
  display: flex;
  flex-direction: column;
}

.MenuItems_divider__1Eoix {
  height: 4px;
  display: flex;
  flex-direction: row;
  /* margin-top: 2rem; */
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  width: 50px;
}

.MenuItems_blue__3tXIK {
  background-color: rgb(0, 0, 0);
}

.MenuItems_purple__3qv-p {
  background-color: rgb(125, 0, 118);
}


.MenuItems_left__3OfCN {
  /* background-color: rgb(50, 50, 50); */
  width: 200px;
}

.MenuItems_right__2AlKM {
  background-color: transparent;
  width: 0;
}

@media
only screen and (-webkit-min-device-pixel-ratio: 1.25),
only screen and ( min--moz-device-pixel-ratio: 1.25),
only screen and ( min-device-pixel-ratio: 1.25),
only screen and ( -webkit-min-device-pixel-ratio: 2.0833333333333335),
only screen and ( min-resolution: 200dpi),
only screen and ( -webkit-min-device-pixel-ratio: 1.25),
only screen and ( min-resolution: 1.25dppx) {
  .MenuItems_left__3OfCN{
    width: 130px;
  }
}

@media only screen and (max-device-width: 767px) {
  .MenuItems_divider__1Eoix {
    height: 5px;
    margin-left: auto
  }

  .MenuItems_left__3OfCN {
    background-color: transparent;
    width: 100%;
  }

  .MenuItems_right__2AlKM {
    /* background-color: rgb(50, 50, 50); */
    width: 0%;
  }
}

.MenuItem_menuItem__28mFk {
  /* line-height: 2rem; */
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
  -webkit-transition-property: transform;
  -webkit-transition-duration: 1.0s;
  -webkit-transition-timing-function: ease;

  -moz-transition-property: transform;
  -moz-transition-duration: 1.0s;
  -moz-transition-timing-function: ease;

  -o-transition-property: transform;
  -o-transition-duration: 1.0s;
  -o-transition-timing-function: ease;

  transition-property: -webkit-transform;

  transition-property: transform;

  transition-property: transform, -webkit-transform;
  transition-duration: 1.0s;
  transition-timing-function: ease;
  margin-bottom: 0.5rem;
}

.MenuItem_menuItem__28mFk a {
  /* color: rgb(50, 50, 50); */
  text-decoration: none;
  font-size: 2rem;
  /* font-weight: bold; */
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  outline: 0;
  font-weight: bold;
}

.MenuItem_menuItem__28mFk a:active,
.MenuItem_menuItem__28mFk a:hover{
  font-style: italic;
}

/* .contact {
  border-top: 1px solid rgb(50, 50, 50);
  margin-top: 0.4rem;
  padding-top: 0.35rem;
} */

.MenuItem_active__2_ZpZ a {
  /* font-weight: bold; */
}

.MenuItem_active__2_ZpZ {
  /* font-weight: bold; */
  /* text-decoration: line-through; */
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

@media
only screen and (-webkit-min-device-pixel-ratio: 1.25),
only screen and ( min--moz-device-pixel-ratio: 1.25),
only screen and ( min-device-pixel-ratio: 1.25),
only screen and ( -webkit-min-device-pixel-ratio: 2.0833333333333335),
only screen and ( min-resolution: 200dpi),
only screen and ( -webkit-min-device-pixel-ratio: 1.25),
only screen and ( min-resolution: 1.25dppx) {
  .MenuItem_menuItem__28mFk a {
    font-size: 1.8rem;
  }
}

@media only screen and (max-device-width: 767px){
  .MenuItem_menuItem__28mFk {
    margin-left: auto;
    line-height: 2rem;
  }

  .MenuItem_menuItem__28mFk a {
    /* color: rgb(50, 50, 50); */
    text-decoration: none;
    font-size: 2rem;
    font-weight: 100;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
    outline: 0;
    font-weight: bold;
  }

  .MenuItem_active__2_ZpZ {
    /* font-weight: bold; */
    /* text-decoration: line-through; */
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

/* @media only screen and (min-device-width: 767px) and (max-device-width: 812px){
  .menuItem a {
    font-size:  0.9rem;
  }
}

@media only screen and (min-device-width: 812px) and (max-device-width: 1280px) {
  .menuItem a {
    font-size: 0.65rem;
  }

  .menuItem {
    line-height: 0.75rem;
  }

  .contact {
    margin-top: 0.3rem;
    padding-top: 0.25rem;
  }
} */

.Language_language__38OdU {
  position: fixed;
  right: 0;
  text-align: right;
  font-size: 2rem;
  color: rgb(0, 0, 0);
  margin-left: 0;
  margin-right: 4px;
}

.Language_language__38OdU a {
  border: 0;
  padding: 0;
  margin: 0;
  background-color: transparent;
  min-width: 20px;
  /* font-size: 1.5rem; */
  text-decoration: none;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  outline: 0;
  font-weight: bold;
}

.Language_language__38OdU a:hover{
  cursor: pointer;
  font-style: italic;
}

.Language_language__38OdU a:focus {
  outline: 0;
}

.Language_language__38OdU a::-moz-focus-inner {
  border: 0;
}

/* .btn {
  font-weight: 100;
} */

/* .nl, .en {

}

.notActive {
  color: rgb(50, 50, 50);
}

.active {
  color: rgb(100, 15, 15);
} */

@media
only screen and (-webkit-min-device-pixel-ratio: 1.25),
only screen and ( min--moz-device-pixel-ratio: 1.25),
only screen and ( min-device-pixel-ratio: 1.25),
only screen and ( -webkit-min-device-pixel-ratio: 2.0833333333333335),
only screen and ( min-resolution: 200dpi),
only screen and ( -webkit-min-device-pixel-ratio: 1.25),
only screen and ( min-resolution: 1.25dppx) {
  .Language_language__38OdU {
    font-size: 1.8rem;
  }
}

@media only screen and (max-device-width: 767px) {
  .Language_language__38OdU{
    /* display: none; */
    left: 0;
    bottom: 0;
    right: auto;
    margin-left: 4px;
    margin-right: 0;
    font-size: 1.7rem;
  }

  .Language_language__38OdU a:hover{
    font-weight: 100;
  }
}

/* @media only screen and (min-device-width: 767px) and (max-device-width: 812px){
  .language {
    font-size: 1.0rem;
  }

  .language button {
    font-size: 1.0rem;
  }
} */

.MobileMenu_menu__1r9jo {
  /* height: 100%; */
  background-color: transparent;
  /* margin-top: 2rem; */
  display: flex;
  flex-direction: column;
}

.MobileMenu_header__2M63_ {
  margin-left: auto;
  margin-right: auto;
  /* width: 340px; */
  width: 100%;
  display: flex;
  flex-direction: row;
}

.MobileMenu_title__BO2L4 {
  flex: 1 1;
  color: #fff;
  font-size: 6rem;
  font-weight: bold;
  /* transform: translateY(-1rem) translateX(-0.3rem); */
}

/* .link {
  margin-top: auto;
  font-size: 1.03rem;
  height: 1rem;
}

.link img {
  height: 0.75rem;
  width: auto;
}

.hamburger a {
  color: rgb(50, 50, 50);
  text-decoration: none;
  border: 0;
  padding: 0;
  margin: 0;
  font-weight: bold;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
}

.hamburger a:active,
.hamburger a:hover{
  font-weight: bold;
}

.hamburger a:focus {
  outline: 0;
} */

/* .content {
  flex: 1;
  margin-top: 2rem;
  margin-bottom: 2rem;
} */

.HamburgerMenu_wrapper__3570g {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  margin-right: 3px;
}

.HamburgerMenu_HamburgerMenu__DljVe {
  margin-left: auto;
  width: 2.3rem;
}

.HamburgerMenu_hbBtn__2ppb3 {

}

.HamburgerMenu_dropdown__3D3-z {
  margin-left: auto;
  margin-top: 0.5rem;
}

.HamburgerMenu_hbBtn__2ppb3 img {
  width: 2rem;
}

.DropDown_DropDown__HlITf {
  /* background-color: rgb(220, 220, 220); */
}

.DropDown_bottom__3ywPW {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.SocialMenu_socialmenu__ZpCgz {
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 4px;
}

.SocialMenu_wrapper__3uJzk {
  margin-top: auto;
  margin-left: auto;
}

.SocialMenu_wrapper__3uJzk img {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.5rem;
}

@media only screen and (max-device-width: 767px) {
  .SocialMenu_socialmenu__ZpCgz{
    /* display: none; */
  }
}

.HamburgerIcon_HamburgerIcon__1g1hL {

}

.HamburgerIcon_image__2032g img {
  width: 2.3rem;
  margin-top: 5px;
}

.Logo_Logo__2gChi {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.Logo_title__2SKvJ {
  display: flex;
  flex-direction: row;
  font-size: 2rem;
  color: rgb(0, 0, 0);
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
  font-weight: bold !important;
}

.Logo_Logo__2gChi a {
  text-decoration: none;
  outline: 0;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  font-weight: bold;
}

/* .Logo a:hover {
  font-style: italic;
} */

.Logo_title1__fWkSi {
  margin-right: 20px;
}

.Logo_title2__3nOsp {

}

.Logo_title3__1LjMg {
  margin-left: 20px
}

.Logo_subtitle__3GaqC {
  font-size: 2rem;
  color: rgb(0, 0, 0);
  margin-top: 2rem;
}

@media
only screen and (-webkit-min-device-pixel-ratio: 1.25),
only screen and ( min--moz-device-pixel-ratio: 1.25),
only screen and ( min-device-pixel-ratio: 1.25),
only screen and ( -webkit-min-device-pixel-ratio: 2.0833333333333335),
only screen and ( min-resolution: 200dpi),
only screen and ( -webkit-min-device-pixel-ratio: 1.25),
only screen and ( min-resolution: 1.25dppx) {
  .Logo_title__2SKvJ {
    font-size: 1.8rem;
  }

  .Logo_subtitle__3GaqC {
    font-size: 1rem;
    margin-top: 1rem;
  }
}

@media only screen and (max-device-width: 319px) {
  .Logo_title1__fWkSi {
    margin-right: 20px;
  }
}

@media only screen and (max-device-width: 767px) {
  .Logo_Logo__2gChi a:hover {
    font-weight: 100;
  }

  .Logo_title__2SKvJ {
    font-size: 3rem;
  }

  .Logo_title1__fWkSi {
    margin-right: 50px;
  }

  .Logo_title2__3nOsp {
    /* margin-right: 25%; */
  }

  .Logo_subtitle__3GaqC {
    display: none;
  }

  .Logo_title3__1LjMg {
    display: none;
  }
}

.Projects_projects__17wGL {
  height: 100%;
  width: 100%;
  overflow: hidden;
  color: rgb(0, 0, 0);
}

.Projects_items__2Q_U5 {
  height: 100%;
  width: 100%;
}

.Projects_swipe__2Ta8q {
  height: 100%;
  width: 100%;
}

@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation: portrait){
  .Projects_items__2Q_U5 {
    /* position: relative; */

  }
}

@media only screen and (max-device-width: 767px) {
  .Projects_projects__17wGL {

  }

  .Projects_items__2Q_U5 {
    position: absolute;
    top: 50%;
  }
}

.ProjectItem_projectItem__3d6Z1 {
  display: flex;
  flex-direction: column;
  position: relative;
  /* margin-bottom: 50px; */
  margin-bottom: 70px;
  /* -webkit-transform: translateY() */
  /* transform: translateY(30%); */
  width: 900px;
  height: 600px;
  margin-left: auto;
  margin-right: auto;
}

.ProjectItem_wrapper__3fFJT {
  margin: auto;
  -webkit-transition-property: transform, filter, opacity;
  -webkit-transition-duration: 1.0s;
  -webkit-transition-timing-function: ease;

  -moz-transition-property: transform, filter, opacity;
  -moz-transition-duration: 1.0s;
  -moz-transition-timing-function: ease;

  -o-transition-property: transform, filter, opacity;
  -o-transition-duration: 1.0s;
  -o-transition-timing-function: ease;

  transition-property: opacity, -webkit-transform, -webkit-filter;

  transition-property: transform, filter, opacity;

  transition-property: transform, filter, opacity, -webkit-transform, -webkit-filter;
  transition-duration: 1.0s;
  transition-timing-function: ease;
}

.ProjectItem_title__2_Gg4 {
  margin-top: 1rem;
  font-size: 0.77rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.ProjectItem_prevImg__A5stz {
  position: absolute;
  bottom: 0;
  margin-bottom: 40px;
  left: 50%;
  display: none;
}

.ProjectItem_prevImg__A5stz img {
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  /* height: 30px; */
}

.ProjectItem_nextImg__2AjJz {
  position: absolute;
  top: 0;
  left: 50%;
  display: none;
}

.ProjectItem_nextImg__2AjJz img {
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  /* height: 30px; */
  /* transform: translateY(-50%); */
}

.ProjectItem_opacity__KyVIV {
  /* opacity: 0.5; */
  opacity: 1;
  -webkit-transition-property: opacity;
  -webkit-transition-duration: 1.0s;
  -webkit-transition-timing-function: ease;

  -moz-transition-property: opacity;
  -moz-transition-duration: 1.0s;
  -moz-transition-timing-function: ease;

  -o-transition-property: opacity;
  -o-transition-duration: 1.0s;
  -o-transition-timing-function: ease;

  transition-property: opacity;
  transition-duration: 1.0s;
  transition-timing-function: ease;
}

.ProjectItem_description__2qEL4 {
  margin: auto;
}

.ProjectItem_pageCount__1S7pd {
  position: absolute;
  right: 0;
  display: none;
}

.ProjectItem_content__2MKF6 {
  width: 900px;
  height: 600px;
  -webkit-transition-property: transform;
  -webkit-transition-duration: 1.0s;
  -webkit-transition-timing-function: ease;

  -moz-transition-property: transform;
  -moz-transition-duration: 1.0s;
  -moz-transition-timing-function: ease;

  -o-transition-property: transform;
  -o-transition-duration: 1.0s;
  -o-transition-timing-function: ease;

  transition-property: -webkit-transform;

  transition-property: transform;

  transition-property: transform, -webkit-transform;
  transition-duration: 1.0s;
  transition-timing-function: ease;
}

.ProjectItem_container__3WCa3 {
  position: relative;
  display: flex;
  flex-direction: row;
}

.ProjectItem_textWrapper__1LpTN {
  /* position: absolute;
  top: 0;
  left: 0; */
  width: 900px;
  height: 600px;
  display: flex;
  flex-direction: column;
  /* background-color: rgb(220, 220, 220); */
  justify-content: center;
  font-size: 0.9rem;
  /* color: rgb(1, 3, 89); */
}

.ProjectItem_textPurple__1xV8_ {
  /* color: rgb(80, 0, 76); */
  font-weight: bold;
}

.ProjectItem_textContainer__2r0xm {
  position: relative;
  /* width: 600px;
  height: 600px; */
}

.ProjectItem_text__zSnBw {
  margin-top: 1rem;
  font-weight: 300;
  /* width: 600px; */
}

.ProjectItem_textTitle__2-cUU {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.75rem;
  /* width: 600px; */
}

.ProjectItem_textMeta__S8Dxs table{
  /* min-width: 100%; */
}

.ProjectItem_textMetaHeader__mvu2k {
  width: 120px;
  vertical-align: top;
}

.ProjectItem_image__5DQia {
  position: relative;
  width: 900px;
  height: 600px;
  display: flex;
  flex-direction: column;
  background-color: rgb(220, 220, 220);
}

.ProjectItem_image__5DQia img {
  margin: auto;
  width: 900px;
  height: 600px;
}

@media
only screen and (-webkit-min-device-pixel-ratio: 1.25),
only screen and ( min--moz-device-pixel-ratio: 1.25),
only screen and ( min-device-pixel-ratio: 1.25),
only screen and ( -webkit-min-device-pixel-ratio: 2.0833333333333335),
only screen and ( min-resolution: 200dpi),
only screen and ( -webkit-min-device-pixel-ratio: 1.25),
only screen and ( min-resolution: 1.25dppx) {
  .ProjectItem_projectItem__3d6Z1 {
    width: 700px;
    height: 466px;
  }

  .ProjectItem_image__5DQia img {
    width: 700px;
    height: 466px;
  }

  .ProjectItem_image__5DQia {
    width: 700px;
    height: 466px;
  }

  .ProjectItem_textWrapper__1LpTN {
    width: 700px;
    height: 466px;
  }

  .ProjectItem_content__2MKF6 {
    width: 700px;
    height: 466px;
  }

  .ProjectItem_prevImg__A5stz {
    margin-bottom: 15px;
  }
}

@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation: portrait) {
  .ProjectItem_projectItem__3d6Z1 {
    width: 400px;
    height: 266px;
    margin-bottom: 150px;
  }

  .ProjectItem_image__5DQia img {
    width: 400px;
    height: 266px;
  }

  .ProjectItem_image__5DQia {
    width: 400px;
    height: 266px;
  }

  .ProjectItem_textWrapper__1LpTN {
    width: 400px;
    height: 266px;
  }

  .ProjectItem_content__2MKF6 {
    width: 400px;
    height: 266px;
  }

  .ProjectItem_prevImg__A5stz {
    margin-bottom: 15px;
  }
}

@media only screen and (min-device-width: 1921px) and (max-device-width: 2560px)  {
  .ProjectItem_projectItem__3d6Z1 {
    margin-bottom: 250px;
  }
}

/* @media only screen and (max-device-width: 1280px) {
  .image {
    width: 400px;
    height: 400px;
  }

  .textWrapper {
    width: 400px;
    height: 400px;
    font-size: 0.70rem;
  }

  .content {
    width: 400px;
    height: 400px;
  }

  .textMetaHeader {
    width: 90px;
  }
} */

@media only screen and (max-device-width: 767px) {
  .ProjectItem_projectItem__3d6Z1 {
    display: flex;
    flex-direction: column;
    position: relative;
    /* margin-bottom: 70px; */
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 320px;
    height: 213px;
    margin-left: auto;
    margin-right: auto;
  }

  .ProjectItem_image__5DQia {
    /* position: relative; */
    width: 320px;
    height: 213px;
    /* display: flex;
    flex-direction: column;
    background-color: rgb(220, 220, 220); */
  }

  .ProjectItem_image__5DQia img {
    width: 100%;
    height: 100%;
    min-width: 320px;
    min-height: 213px;
  }

  .ProjectItem_text__zSnBw {
    display: none;
  }

  .ProjectItem_textWrapper__1LpTN {
    width: 320px;
    height: 213px;
  }

  .ProjectItem_content__2MKF6 {
    width: 320px;
    height: 213px;
    transition-duration: 0.5s;
  }

  .ProjectItem_wrapper__3fFJT {
    margin: auto;
    transition-duration: 0.5s;
  }

  .ProjectItem_opacity__KyVIV {
    transition-duration: 0.5s;
  }
}

.Navigation_Navigation__tdnPu {
  height: 600px;
  width: 1500px;
  /* transform: translateX(-300px); */
  /* position: absolute;
  top: 0;
  left: 0; */
  display: flex;
  flex-direction: row;
}

.Navigation_swipe__fqyop {
  position: absolute;
  top: 0;
  left: 0;
  height: 600px;
  width: 1500px;
  transform: translateX(-300px);
  -webkit-transform: translateX(-300px);
  -moz-transform: translateX(-300px);
  -o-transform: translateX(-300px);
  -ms-transform: translateX(-300px);
}

.Navigation_btnFull__2Zd6v {
  height: 100%;
  width: 100%;
}

.Navigation_btn__3ijVo {
  height: 100%;
  width: 50%;
}

@media
only screen and (-webkit-min-device-pixel-ratio: 1.25) and (max-device-width: 2560px),
only screen and ( min--moz-device-pixel-ratio: 1.25) and (max-device-width: 2560px),
only screen and ( min-device-pixel-ratio: 1.25) and (max-device-width: 2560px),
only screen and ( -webkit-min-device-pixel-ratio: 2.0833333333333335) and (max-device-width: 2560px),
only screen and ( min-resolution: 200dpi) and (max-device-width: 2560px),
only screen and ( -webkit-min-device-pixel-ratio: 1.25) and (max-device-width: 2560px),
only screen and ( min-resolution: 1.25dppx) and (max-device-width: 2560px) {
  .Navigation_Navigation__tdnPu {
    height: 466px;
    width: 1100px;
    -webkit-transform: translateX(-200px);
            transform: translateX(-200px);
  }

  .Navigation_swipe__fqyop {
    position: absolute;
    top: 0;
    left: 0;
    height: 466px;
    width: 1100px;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

/* @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (max-device-width: 1024px),
only screen and ( min--moz-device-pixel-ratio: 1.25) and (max-device-width: 1024px),
only screen and ( -o-min-device-pixel-ratio: 1.25/1) and (max-device-width: 1024px),
only screen and ( min-device-pixel-ratio: 1.25) and (max-device-width: 1024px),
only screen and ( min-resolution: 200dpi) and (max-device-width: 1024px),
only screen and ( min-resolution: 1.25dppx) and (max-device-width: 1024px) {
  .Navigation {
    height: 266px;
    width: 600px;
    transform: translateX(-100px);
  }

  .swipe {
    position: absolute;
    top: 0;
    left: 0;
    height: 266px;
    width: 600px;
    transform: translateX(0);
  }
} */

@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation: portrait) {
  .Navigation_Navigation__tdnPu {
    width: 600px;
    height: 266px;
  }

  .Navigation_swipe__fqyop {
    height: 266px;
    width: 600px;
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
  }
}

@media only screen and (max-device-width: 767px) {
  .Navigation_Navigation__tdnPu {
    height: 213px;
    width: 100%;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  .Navigation_swipe__fqyop{
    /* position: absolute;
    top: 0;
    left: 0; */
    height: 213px;
    width: 320px;
    display: inherit;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

.FloatingTitle_FloatingTitle__10QDz {
  position: fixed;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.FloatingTitle_titleWrapper__2kUjQ {
  margin-right: 10px;
}

.FloatingTitle_title__qgpDj {
  font-weight: bold;
  font-size: 1.5rem;
  color: rgb(0, 0, 0);
  white-space: nowrap;
}

.FloatingTitle_subTitle__1QeDa {
  display: flex;
  flex-direction: row;
  font-size: 1rem;
}

.FloatingTitle_year__1JIxc {
  margin-left: 8px;
}

.FloatingTitle_arrow__1qNCP {
  /* width: 45px; */
  height: 20px;
  margin: auto;
  -webkit-transform: translateY(25%);
          transform: translateY(25%);
  -webkit-tranform: translateY(25%);
  -moz-transform: translateY(25%);
  -o-transform: translateY(25%);
  -ms-transform: translateY(25%);
}

.FloatingTitle_arrow__1qNCP img {
  width: auto;
  height: 100%;
}

@media
only screen and (-webkit-min-device-pixel-ratio: 1.25),
only screen and ( min--moz-device-pixel-ratio: 1.25),
only screen and ( min-device-pixel-ratio: 1.25),
only screen and ( -webkit-min-device-pixel-ratio: 2.0833333333333335),
only screen and ( min-resolution: 200dpi),
only screen and ( -webkit-min-device-pixel-ratio: 1.25),
only screen and ( min-resolution: 1.25dppx) {
  .FloatingTitle_title__qgpDj {
    font-size: 1.1rem;
  }
}

@media only screen and (max-device-width: 767px) {
  .FloatingTitle_FloatingTitle__10QDz{
    margin-top: 10px;
    /* width: 250px; */
  }

  .FloatingTitle_title__qgpDj {
    font-size: 1.5rem;
  }

  .FloatingTitle_subTitle__1QeDa {
    font-size: 0.9rem;
  }

  .FloatingTitle_year__1JIxc {
    margin-left: 4px;
  }

  .FloatingTitle_arrow__1qNCP {
    width: 50px;
    margin: auto auto auto 0;
  }
}

.Process_process__3Z2EF {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.Process_items__nF7kU {
  height: 100%;
  width: 100%;
}

.Process_swipe__2ZI2t {
  height: 100%;
  width: 100%;
}

.ProcessItem_processItem__3CI1Q {
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: -webkit-transform 1.0s ease-in-out;
  transition: transform 1.0s ease-in-out;
  transition: transform 1.0s ease-in-out, -webkit-transform 1.0s ease-in-out;
}

.ProcessItem_wrapper__3HAAV {
  margin: auto;
}

.ProcessItem_content__2zcJQ {
  width: 600px;
  height: 600px;
}

.ProcessItem_title__3nx4m {
  text-align: center;
  margin-top: 1rem;
  font-size: 0.77rem;
  color: rgb(1, 3, 89);
}

.ProcessItem_text__3o7p6 {
  font-size: 1rem;
  width: 50%;
  margin: 0 auto
}

.ProcessItem_image__1HisF {
  width: 600px;
  height: 600px;
  display: flex;
  flex-direction: column;
}

.ProcessItem_image__1HisF img{
  margin: auto;
}

.ProcessItem_landscape__1m3QM img {
  width: 100%;
  max-width: 600px;
}

.ProcessItem_portrait__3YTEN img {
  height: 100%;
  width: auto;
  max-height: 600px;
}

/* @media only screen and (max-device-width: 1280px) {
  .image {
    width: 400px;
    height: 400px;
  }

  .content {
    width: 400px;
    height: 400px;
  }
} */

@media
only screen and (-webkit-min-device-pixel-ratio: 1.25),
only screen and ( min--moz-device-pixel-ratio: 1.25),
only screen and ( min-device-pixel-ratio: 1.25),
only screen and ( -webkit-min-device-pixel-ratio: 2.0833333333333335),
only screen and ( min-resolution: 200dpi),
only screen and ( -webkit-min-device-pixel-ratio: 1.25),
only screen and ( min-resolution: 1.25dppx) {
  .ProcessItem_content__2zcJQ {
    width: 400px;
    height: 400px;
  }

  .ProcessItem_image__1HisF {
    width: 400px;
    height: 400px;
  }
}

/* @media
only screen and (orientation: landscape),
only screen and (-webkit-min-device-pixel-ratio: 1.25),
only screen and ( min--moz-device-pixel-ratio: 1.25),
only screen and ( -o-min-device-pixel-ratio: 1.25/1),
only screen and ( min-device-pixel-ratio: 1.25),
only screen and ( min-resolution: 200dpi),
only screen and ( min-resolution: 1.25dppx)
{
  .content {
    width: 400px;
    height: 400px;
  }

  .image {
    width: 400px;
    height: 400px;
  }
} */

@media only screen and (max-device-width: 767px) {
  .ProcessItem_content__2zcJQ {
    width: 300px;
    height: 300px;
  }

  .ProcessItem_image__1HisF {
    width: 300px;
    height: 300px;
  }
}

.Navigation_Navigation__2g-MA {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.Navigation_nextBtn__2BUiE {
  margin: auto auto 25px;
}

.Navigation_nextBtn__2BUiE img {
  width: 100%;
  height: auto;
}

.Navigation_nextBtn__2BUiE:hover {
  cursor: pointer;
}

.FloatingTitle_FloatingTitle__2ck7c {
  position: fixed;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.FloatingTitle_titleWrapper__3hv0M {
  /* margin-right: 10px; */
}

.FloatingTitle_title__1xYNL {
  font-weight: 100;
  font-size: 1.5rem;
  color: rgb(1, 3, 89);
  white-space: nowrap;
}

.FloatingTitle_subTitle__KmK3g {
  display: flex;
  flex-direction: row;
  font-size: 1rem;
}

.FloatingTitle_year__2Gk2q {
  margin-left: 8px;
}

.FloatingTitle_arrow__28vna {
  /* width: 45px; */
  height: 20px;
  margin: auto;
  -webkit-transform: translateY(25%);
          transform: translateY(25%);
  -webkit-tranform: translateY(25%);
  -moz-transform: translateY(25%);
  -o-transform: translateY(25%);
  -ms-transform: translateY(25%);
}

.FloatingTitle_arrow__28vna img {
  width: auto;
  height: 100%;
}

@media
only screen and (-webkit-min-device-pixel-ratio: 1.25),
only screen and ( min--moz-device-pixel-ratio: 1.25),
only screen and ( min-device-pixel-ratio: 1.25),
only screen and ( -webkit-min-device-pixel-ratio: 2.0833333333333335),
only screen and ( min-resolution: 200dpi),
only screen and ( -webkit-min-device-pixel-ratio: 1.25),
only screen and ( min-resolution: 1.25dppx) {
  .FloatingTitle_title__1xYNL {
    font-size: 1.1rem;
  }
}

@media only screen and (max-device-width: 767px) {
  .FloatingTitle_FloatingTitle__2ck7c{
    margin-top: 10px;
    /* width: 250px; */
  }

  .FloatingTitle_title__1xYNL {
    font-size: 1.5rem;
  }

  .FloatingTitle_subTitle__KmK3g {
    font-size: 0.9rem;
  }

  .FloatingTitle_year__2Gk2q {
    margin-left: 4px;
  }

  .FloatingTitle_arrow__28vna {
    width: 50px;
    margin: auto auto auto 0;
  }
}

.News_news__3--bm {
  height: 100%;
  width: 100%;
}

.News_items__4NSR3 {
  height: 100%;
  width: 100%;
  /* margin-top: 100px; */
}

@media only screen and (max-device-width: 767px) {
  .News_news__3--bm {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .News_items__4NSR3 {
    margin-top: 100px;
  }
}

.NewsItem_newsItem__3qdv3 {
  display: flex;
  flex-direction: column;
  color: rgb(0, 0, 0);
  margin-bottom: 1rem;
  font-size: 1rem;
}

.NewsItem_wrapper__3vAsq {
  padding-top: 2rem;
  margin: auto;
}

.NewsItem_title__18Dcp {
  margin-bottom: 0.5rem;
  /* font-size: 1rem; */
  font-weight: bold;
  /* color: rgb(80, 0, 76); */
}

.NewsItem_text__31FN6 {
  /* font-size: 1rem; */
  margin: 0 auto;
  font-weight: 300;
}

.NewsItem_text__31FN6 a {
  /* color: rgb(1, 3, 89); */
}

.NewsItem_text__31FN6 p {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-margin-start: 0;
          margin-inline-start: 0;
  -webkit-margin-end: 0;
          margin-inline-end: 0;
}

.NewsItem_image__2aPFA {
  width: 500px;
  /* height: 200px; */
}

.NewsItem_landscape__v122x img {
  width: 100%;
  /* max-width: 400px; */
}

.NewsItem_portrait__24FFv img {
  height: 100%;
  width: auto;
  /* max-height: 400px; */
}

.NewsItem_textWrapper__2eE51 {
  /* margin-left: 2rem; */
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  width: 500px;
}


.NewsItem_content__mvrW7 {
  display: flex;
  flex-direction: column;
}

.NewsItem_date__301Qx {
  /* font-size: 1rem; */
}

/* @media only screen and (max-device-width: 1280px) {
  .image {
    width: 150px;
    height: 150px;
  }

  .textWrapper {
    width: 400px;
  }

  .text {
    font-size: 0.70rem;
  }

  .title {
    font-size: 0.8rem;
  }

  .date {
    font-size: 0.8rem;
  }
} */

@media
only screen and (-webkit-min-device-pixel-ratio: 1.25),
only screen and ( min--moz-device-pixel-ratio: 1.25),
only screen and ( min-device-pixel-ratio: 1.25),
only screen and ( -webkit-min-device-pixel-ratio: 2.0833333333333335),
only screen and ( min-resolution: 200dpi),
only screen and ( -webkit-min-device-pixel-ratio: 1.25),
only screen and ( min-resolution: 1.25dppx) {
  .NewsItem_newsItem__3qdv3 {
    font-size: 0.8rem;
  }
}

@media only screen and (max-device-width: 767px) {
  .NewsItem_wrapper__3vAsq {
    padding-top: 0;
    padding-bottom: 2rem;
  }

  .NewsItem_content__mvrW7 {
    flex-direction: column;
  }

  .NewsItem_image__2aPFA {
    width: 300px;
    height: auto;
    margin: auto;
  }

  .NewsItem_textWrapper__2eE51 {
    margin-left: 0;
    width: 300px;
  }
}

.Cv_cv__3BbT1 {
  height: 100%;
  width: 100%;
  /* overflow: visible; */
}

.Cv_items__EhNUD {
  height: 100%;
  width: 100%;
}

@media only screen and (max-device-width: 767px) {
  .Cv_cv__3BbT1 {
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.CvItem_cvItem__zT6O6 {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
}

.CvItem_wrapper__2ZQl- {
  padding-top: 100px;
  margin: auto;
  max-width: 600px;
  color: rgb(0, 0, 0);
  font-weight: 300;
}

.CvItem_title__2M4Ad {
  margin-bottom: 0.45rem;
  /* font-size: 0.9rem; */
  font-weight: 400;
}

.CvItem_text__2QAbL {
  /* font-size: 1rem; */
  margin: 0 auto
}

.CvItem_text__2QAbL p {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
}

.CvItem_mobileText__2Xn_7 {
  display: none;
}

.CvItem_mobileText__2Xn_7 p {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
}

.CvItem_image__nzqyQ {
  width: 49%;
}

.CvItem_images__1CtTP {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.CvItem_landscape__10r96 img {
  width: 100%;
}

.CvItem_portrait__nyzkM img {
  width: 100%;
}

.CvItem_content__2maTm {
  display: flex;
  flex-direction: column;
}

/* @media only screen and (max-device-width: 1280px) {
  .wrapper {
    max-width: 400px;
  }

  .text {
    font-size: 0.70rem;
  }
} */

@media
only screen and (-webkit-min-device-pixel-ratio: 1.25),
only screen and ( min--moz-device-pixel-ratio: 1.25),
only screen and ( min-device-pixel-ratio: 1.25),
only screen and ( -webkit-min-device-pixel-ratio: 2.0833333333333335),
only screen and ( min-resolution: 200dpi),
only screen and ( -webkit-min-device-pixel-ratio: 1.25),
only screen and ( min-resolution: 1.25dppx) {
  .CvItem_cvItem__zT6O6 {
    font-size: 0.8rem;
  }
}

@media only screen and (max-device-width: 767px) {
  .CvItem_wrapper__2ZQl- {
    width: 300px;
  }

  .CvItem_image__nzqyQ {
    display: none;
  }

  .CvItem_text__2QAbL {
    display: none;
  }

  .CvItem_mobileText__2Xn_7 {
    display: inherit;
    font-size: 1rem;
  }
}

.Contact_contact__2mZWl {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.Contact_items__1YUDE {
  height: 100%;
  width: 100%;
}

@media only screen and (max-device-width: 767px) {
  .Contact_items__1YUDE {
    display: flex;
    flex-direction: column;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.ContactItem_ContactItem__GJPJa {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-weight: 300;
  color: rgb(0, 0, 0);
}

.ContactItem_wrapper__W-ALF {
  margin: auto;
}

.ContactItem_address__3ksrW {
  font-size: 0.9rem;
}

.ContactItem_contactDetails__2wE2C {
  font-size: 0.9rem;
  margin-top: 1rem;
}

.ContactItem_text__vNU6R {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
}

.ContactItem_content__3sr8Z {
  display: flex;
  flex-direction: column;
}

.ContactItem_map__3IXUU iframe {
  max-width: 600px;
  max-height: 400px;
}

.ContactItem_addressHeader__26H9o {
  font-weight: bold;
  margin-bottom: 0.3rem;
}

@media only screen and (max-device-width: 1280px) {
  .ContactItem_map__3IXUU iframe {
    max-width: 400px;
    max-height: 400px;
  }

  .ContactItem_address__3ksrW {
    font-size: 0.75rem;
  }

  .ContactItem_contactDetails__2wE2C {
    margin-top: 1rem;
    font-size: 0.75rem;
  }
}

@media only screen and (max-device-width: 812px) {
  .ContactItem_ContactItem__GJPJa {
    margin: auto;
  }

  .ContactItem_map__3IXUU iframe {
    max-width: 320px;
    max-height: 240px;
  }
}

.ProjectOverview_ProjectOverview__DoLlq {
  height: 100%;
  width: 1020px;
  margin: auto;
}

.ProjectOverview_wrapper__3h8z9 {
  margin-top: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  align-content: flex-start;
}

@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation: portrait) {
  .ProjectOverview_ProjectOverview__DoLlq {
    width: 520px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .ProjectOverview_wrapper__3h8z9 {
    margin-top: 100px;
  }
}

@media only screen and (max-device-width: 767px) {
  .ProjectOverview_ProjectOverview__DoLlq {
    width: 320px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .ProjectOverview_wrapper__3h8z9 {
    margin-top: 100px;
  }
}

.Item_Item__24lk- {
  position: relative;
  width: 320px;
  /* height: 200px; */
  margin: 10px 10px 30px;
}

.Item_wrapper__3UiY2 {
  display: flex;
  flex-direction: column;
}

.Item_wrapper__3UiY2 a{
  color: rgb(50, 50, 50);
  text-decoration: none;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  outline: 0;
}

.Item_textWrapper__3-Gqm {
  /* position: absolute;
  top: 0;
  left: 0; */
  margin-top: 10px;
  display: flex;
  width: 100%;
  height: 100%;
}

.Item_text__23v8V {
  width: 100%;
  margin: auto;
  /* opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  -o-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease; */
  /* z-index: 100; */
}

.Item_image__2EBJf {
  /* -webkit-filter: grayscale(0%) blur(0);
  -moz-filter: grayscale(0%) blur(0);
  -o-filter: grayscale(0%) blur(0);
  -ms-filter: grayscale(0%) blur(0);
  filter: grayscale(0%) blur(0);
  opacity: 1;

  -webkit-transition-property: opacity, filter;
  -webkit-transition-duration: 0.25s;
  -webkit-transition-timing-function: ease;

  -moz-transition-property: opacity, filter;
  -moz-transition-duration: 0.25s;
  -moz-transition-timing-function: ease;

  -o-transition-property: opacity, filter;
  -o-transition-duration: 0.25s;
  -o-transition-timing-function: ease;

  transition-property: opacity, filter;
  transition-duration: 0.25s;
  transition-timing-function: ease; */
}

/* @media
only screen and (-webkit-min-device-pixel-ratio: 1.25),
only screen and ( min--moz-device-pixel-ratio: 1.25),
only screen and ( -o-min-device-pixel-ratio: 1.25/1),
only screen and ( min-device-pixel-ratio: 1.25),
only screen and ( min-resolution: 200dpi),
only screen and ( min-resolution: 1.25dppx) {
  .Item {
    width: 240px;
  }
} */

@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation: portrait) {
  .Item_Item__24lk- {
    width: 240px;
  }
}

@media only screen and (max-device-width: 767px) {
  .Item_Item__24lk- {
    /* position: relative; */
    width: 320px;
    /* height: 300px; */
    margin: 0 0 30px;;
  }

  .Item_image__2EBJf {
    /* height: 300px; */
  }

  .Item_textWrapper__3-Gqm {
    margin-top: 5px;
  }
}

.Image_Image__Bs0-1 {
  /* max-width: 600px;
  max-height: 600px; */
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
}

.Image_Image__Bs0-1 img {
  width: 320px;
  height: 213px;
  /* height: auto; */
}

.Image_greyscale__3awwF {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.9;
}

/* @media
only screen and (-webkit-min-device-pixel-ratio: 1.25),
only screen and ( min--moz-device-pixel-ratio: 1.25),
only screen and ( -o-min-device-pixel-ratio: 1.25/1),
only screen and ( min-device-pixel-ratio: 1.25),
only screen and ( min-resolution: 200dpi),
only screen and ( min-resolution: 1.25dppx) {
  .Image img {
    width: 240px;
    height: 159px;
  }
} */

@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation: portrait) {
  .Image_Image__Bs0-1 img {
    width: 240px;
    height: 159px;
  }
}

@media only screen and (max-device-width: 767px) {
  .Image_Image__Bs0-1 img {
    width: 320px;
    height: 213px;
  }
}

.Text_Text__38Wqr {
  width: 320px;
  font-weight: 300;
  color: rgb(0, 0, 0);
  font-size: 1rem;
}

.Text_Text__38Wqr table {
  width: 320px;
  border-spacing: 0;
  /* font-size: 1rem; */
}

.Text_titleWrapper__3vL00{
  display: flex;
  flex-direction: row;
}

.Text_title__BJu3S {
  font-weight: 100;
  /* font-size: 1rem; */
  /* color: rgb(80, 0, 76); */
  font-weight: bold;
  margin-right: 10px;
}

.Text_arrow__aCp2N img{
  width: 30px;
}

.Text_textItemTitle__2Ec6D {
  width: 33%;
  vertical-align: top;
  /* font-weight: 100; */
}

.Text_textItem__2Z0zh {
  text-align: left;
  font-weight: 400;
  /* font-weight: bold; */
  /* color: rgb(80, 0, 76) */
}

@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation: portrait) {
  .Text_Text__38Wqr {
    width: 240px;
  }

  .Text_Text__38Wqr table {
    width: 240px;
  }
}

@media
only screen and (-webkit-min-device-pixel-ratio: 1.25),
only screen and ( min--moz-device-pixel-ratio: 1.25),
only screen and ( min-device-pixel-ratio: 1.25),
only screen and ( -webkit-min-device-pixel-ratio: 2.0833333333333335),
only screen and ( min-resolution: 200dpi),
only screen and ( -webkit-min-device-pixel-ratio: 1.25),
only screen and ( min-resolution: 1.25dppx) {
  .Text_Text__38Wqr {
    font-size: 0.8rem;
  }
}

.LandingPage_landingPage__2cm7r {
  height: 100vh;
  overflow: hidden;
  position: relative;
  transition: -webkit-transform 0.5s ease-out;
  transition: transform 0.5s ease-out;
  transition: transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
}

.LandingPage_container__2YFC_ {
  position: relative;
  height: 100%;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.LandingPage_phContainer__3kU8C {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
}

.LandingPage_placeholder__hAKrn {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.LandingPage_phText__26xzl {
  margin: auto;
  padding-top: 2rem;
  font-size: 2rem;
  font-weight: bold;
  color: rgb(0, 0, 0);
}

.LandingPage_phLoader__1sevx {
  margin: auto;
  /* width: 50px;
  height: 50px; */
}

.LandingPage_bg__1Pdms {
  position: absolute;
}

.LandingPage_wrapper__18e2g {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  cursor: pointer;
}

.LandingPage_textWrapper__1OxQC {
  flex: 1 1;
  display: flex;
  flex-direction: row;
}

.LandingPage_title__3XEHm {
  margin-top: 25px;
  margin-left: 50px;
  z-index: 1;
  font-size: 1.1rem;
  color: rgb(255,255,255);
  flex: 1 1;
  font-weight: bold;
}

.LandingPage_description__rjDNb {
  text-align: right;
  margin-top: 220px;
  margin-right: 220px;
  font-size: 2rem;
}

.LandingPage_descr__1lqpw {
  font-weight: bold;
}

.LandingPage_descrSub__1eEjf {

}

.LandingPage_enterButton__3ZQiL {
  z-index: 1;
  margin: auto;
  padding-bottom: 50px;
  height: 100px;
}

.LandingPage_enterButton__3ZQiL img {
  height: 100%;
  cursor: pointer;
}

@media only screen and (max-device-width: 1280px) {
  .LandingPage_enterButton__3ZQiL {
    height: 75px;
  }

  .LandingPage_title__3XEHm {
    font-size: 0.9rem;
  }
}

