.cvItem {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
}

.wrapper {
  padding-top: 100px;
  margin: auto;
  max-width: 600px;
  color: rgb(0, 0, 0);
  font-weight: 300;
}

.title {
  margin-bottom: 0.45rem;
  /* font-size: 0.9rem; */
  font-weight: 400;
}

.text {
  /* font-size: 1rem; */
  margin: 0 auto
}

.text p {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.mobileText {
  display: none;
}

.mobileText p {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.image {
  width: 49%;
}

.images {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.landscape img {
  width: 100%;
}

.portrait img {
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
}

/* @media only screen and (max-device-width: 1280px) {
  .wrapper {
    max-width: 400px;
  }

  .text {
    font-size: 0.70rem;
  }
} */

@media
only screen and (-webkit-min-device-pixel-ratio: 1.25),
only screen and ( min--moz-device-pixel-ratio: 1.25),
only screen and ( -o-min-device-pixel-ratio: 1.25/1),
only screen and ( min-device-pixel-ratio: 1.25),
only screen and ( min-resolution: 200dpi),
only screen and ( min-resolution: 1.25dppx) {
  .cvItem {
    font-size: 0.8rem;
  }
}

@media only screen and (max-device-width: 767px) {
  .wrapper {
    width: 300px;
  }

  .image {
    display: none;
  }

  .text {
    display: none;
  }

  .mobileText {
    display: inherit;
    font-size: 1rem;
  }
}
