.Image {
  /* max-width: 600px;
  max-height: 600px; */
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
}

.Image img {
  width: 320px;
  height: 213px;
  /* height: auto; */
}

.greyscale {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.9;
}

/* @media
only screen and (-webkit-min-device-pixel-ratio: 1.25),
only screen and ( min--moz-device-pixel-ratio: 1.25),
only screen and ( -o-min-device-pixel-ratio: 1.25/1),
only screen and ( min-device-pixel-ratio: 1.25),
only screen and ( min-resolution: 200dpi),
only screen and ( min-resolution: 1.25dppx) {
  .Image img {
    width: 240px;
    height: 159px;
  }
} */

@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation: portrait) {
  .Image img {
    width: 240px;
    height: 159px;
  }
}

@media only screen and (max-device-width: 767px) {
  .Image img {
    width: 320px;
    height: 213px;
  }
}
