.projectItem {
  display: flex;
  flex-direction: column;
  position: relative;
  /* margin-bottom: 50px; */
  margin-bottom: 70px;
  /* -webkit-transform: translateY() */
  /* transform: translateY(30%); */
  width: 900px;
  height: 600px;
  margin-left: auto;
  margin-right: auto;
}

.wrapper {
  margin: auto;
  -webkit-transition-property: transform, filter, opacity;
  -webkit-transition-duration: 1.0s;
  -webkit-transition-timing-function: ease;

  -moz-transition-property: transform, filter, opacity;
  -moz-transition-duration: 1.0s;
  -moz-transition-timing-function: ease;

  -o-transition-property: transform, filter, opacity;
  -o-transition-duration: 1.0s;
  -o-transition-timing-function: ease;

  transition-property: transform, filter, opacity;
  transition-duration: 1.0s;
  transition-timing-function: ease;
}

.title {
  margin-top: 1rem;
  font-size: 0.77rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.prevImg {
  position: absolute;
  bottom: 0;
  margin-bottom: 40px;
  left: 50%;
  display: none;
}

.prevImg img {
  transform: translateX(-50%);
  /* height: 30px; */
}

.nextImg {
  position: absolute;
  top: 0;
  left: 50%;
  display: none;
}

.nextImg img {
  transform: translateX(-50%);
  /* height: 30px; */
  /* transform: translateY(-50%); */
}

.opacity {
  /* opacity: 0.5; */
  opacity: 1;
  -webkit-transition-property: opacity;
  -webkit-transition-duration: 1.0s;
  -webkit-transition-timing-function: ease;

  -moz-transition-property: opacity;
  -moz-transition-duration: 1.0s;
  -moz-transition-timing-function: ease;

  -o-transition-property: opacity;
  -o-transition-duration: 1.0s;
  -o-transition-timing-function: ease;

  transition-property: opacity;
  transition-duration: 1.0s;
  transition-timing-function: ease;
}

.description {
  margin: auto;
}

.pageCount {
  position: absolute;
  right: 0;
  display: none;
}

.content {
  width: 900px;
  height: 600px;
  -webkit-transition-property: transform;
  -webkit-transition-duration: 1.0s;
  -webkit-transition-timing-function: ease;

  -moz-transition-property: transform;
  -moz-transition-duration: 1.0s;
  -moz-transition-timing-function: ease;

  -o-transition-property: transform;
  -o-transition-duration: 1.0s;
  -o-transition-timing-function: ease;

  transition-property: transform;
  transition-duration: 1.0s;
  transition-timing-function: ease;
}

.container {
  position: relative;
  display: flex;
  flex-direction: row;
}

.textWrapper {
  /* position: absolute;
  top: 0;
  left: 0; */
  width: 900px;
  height: 600px;
  display: flex;
  flex-direction: column;
  /* background-color: rgb(220, 220, 220); */
  justify-content: center;
  font-size: 0.9rem;
  /* color: rgb(1, 3, 89); */
}

.textPurple {
  /* color: rgb(80, 0, 76); */
  font-weight: bold;
}

.textContainer {
  position: relative;
  /* width: 600px;
  height: 600px; */
}

.text {
  margin-top: 1rem;
  font-weight: 300;
  /* width: 600px; */
}

.textTitle {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.75rem;
  /* width: 600px; */
}

.textMeta table{
  /* min-width: 100%; */
}

.textMetaHeader {
  width: 120px;
  vertical-align: top;
}

.image {
  position: relative;
  width: 900px;
  height: 600px;
  display: flex;
  flex-direction: column;
  background-color: rgb(220, 220, 220);
}

.image img {
  margin: auto;
  width: 900px;
  height: 600px;
}

@media
only screen and (-webkit-min-device-pixel-ratio: 1.25),
only screen and ( min--moz-device-pixel-ratio: 1.25),
only screen and ( -o-min-device-pixel-ratio: 1.25/1),
only screen and ( min-device-pixel-ratio: 1.25),
only screen and ( min-resolution: 200dpi),
only screen and ( min-resolution: 1.25dppx) {
  .projectItem {
    width: 700px;
    height: 466px;
  }

  .image img {
    width: 700px;
    height: 466px;
  }

  .image {
    width: 700px;
    height: 466px;
  }

  .textWrapper {
    width: 700px;
    height: 466px;
  }

  .content {
    width: 700px;
    height: 466px;
  }

  .prevImg {
    margin-bottom: 15px;
  }
}

@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation: portrait) {
  .projectItem {
    width: 400px;
    height: 266px;
    margin-bottom: 150px;
  }

  .image img {
    width: 400px;
    height: 266px;
  }

  .image {
    width: 400px;
    height: 266px;
  }

  .textWrapper {
    width: 400px;
    height: 266px;
  }

  .content {
    width: 400px;
    height: 266px;
  }

  .prevImg {
    margin-bottom: 15px;
  }
}

@media only screen and (min-device-width: 1921px) and (max-device-width: 2560px)  {
  .projectItem {
    margin-bottom: 250px;
  }
}

/* @media only screen and (max-device-width: 1280px) {
  .image {
    width: 400px;
    height: 400px;
  }

  .textWrapper {
    width: 400px;
    height: 400px;
    font-size: 0.70rem;
  }

  .content {
    width: 400px;
    height: 400px;
  }

  .textMetaHeader {
    width: 90px;
  }
} */

@media only screen and (max-device-width: 767px) {
  .projectItem {
    display: flex;
    flex-direction: column;
    position: relative;
    /* margin-bottom: 70px; */
    transform: translateY(-50%);
    width: 320px;
    height: 213px;
    margin-left: auto;
    margin-right: auto;
  }

  .image {
    /* position: relative; */
    width: 320px;
    height: 213px;
    /* display: flex;
    flex-direction: column;
    background-color: rgb(220, 220, 220); */
  }

  .image img {
    width: 100%;
    height: 100%;
    min-width: 320px;
    min-height: 213px;
  }

  .text {
    display: none;
  }

  .textWrapper {
    width: 320px;
    height: 213px;
  }

  .content {
    width: 320px;
    height: 213px;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;
  }

  .wrapper {
    margin: auto;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;
  }

  .opacity {
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;
  }
}
