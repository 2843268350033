.ContactItem {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-weight: 300;
  color: rgb(0, 0, 0);
}

.wrapper {
  margin: auto;
}

.address {
  font-size: 0.9rem;
}

.contactDetails {
  font-size: 0.9rem;
  margin-top: 1rem;
}

.text {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
}

.content {
  display: flex;
  flex-direction: column;
}

.map iframe {
  max-width: 600px;
  max-height: 400px;
}

.addressHeader {
  font-weight: bold;
  margin-bottom: 0.3rem;
}

@media only screen and (max-device-width: 1280px) {
  .map iframe {
    max-width: 400px;
    max-height: 400px;
  }

  .address {
    font-size: 0.75rem;
  }

  .contactDetails {
    margin-top: 1rem;
    font-size: 0.75rem;
  }
}

@media only screen and (max-device-width: 812px) {
  .ContactItem {
    margin: auto;
  }

  .map iframe {
    max-width: 320px;
    max-height: 240px;
  }
}
