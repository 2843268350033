.FloatingTitle {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.titleWrapper {
  margin-right: 10px;
}

.title {
  font-weight: bold;
  font-size: 1.5rem;
  color: rgb(0, 0, 0);
  white-space: nowrap;
}

.subTitle {
  display: flex;
  flex-direction: row;
  font-size: 1rem;
}

.year {
  margin-left: 8px;
}

.arrow {
  /* width: 45px; */
  height: 20px;
  margin: auto;
  transform: translateY(25%);
  -webkit-tranform: translateY(25%);
  -moz-transform: translateY(25%);
  -o-transform: translateY(25%);
  -ms-transform: translateY(25%);
}

.arrow img {
  width: auto;
  height: 100%;
}

@media
only screen and (-webkit-min-device-pixel-ratio: 1.25),
only screen and ( min--moz-device-pixel-ratio: 1.25),
only screen and ( -o-min-device-pixel-ratio: 1.25/1),
only screen and ( min-device-pixel-ratio: 1.25),
only screen and ( min-resolution: 200dpi),
only screen and ( min-resolution: 1.25dppx) {
  .title {
    font-size: 1.1rem;
  }
}

@media only screen and (max-device-width: 767px) {
  .FloatingTitle{
    margin-top: 10px;
    /* width: 250px; */
  }

  .title {
    font-size: 1.5rem;
  }

  .subTitle {
    font-size: 0.9rem;
  }

  .year {
    margin-left: 4px;
  }

  .arrow {
    width: 50px;
    margin: auto auto auto 0;
  }
}
