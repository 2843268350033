.wrapper {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  margin-right: 3px;
}

.HamburgerMenu {
  margin-left: auto;
  width: 2.3rem;
}

.hbBtn {

}

.dropdown {
  margin-left: auto;
  margin-top: 0.5rem;
}

.hbBtn img {
  width: 2rem;
}
