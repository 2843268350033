.landingPage {
  height: 100vh;
  overflow: hidden;
  position: relative;
  -webkit-transition: transform 0.5s ease-out;
  -moz-transition: transform 0.5s ease-out;
  -o-transition: transform 0.5s ease-out;
  transition: transform 0.5s ease-out;
}

.container {
  position: relative;
  height: 100%;
  opacity: 0;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}

.phContainer {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
}

.placeholder {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.phText {
  margin: auto;
  padding-top: 2rem;
  font-size: 2rem;
  font-weight: bold;
  color: rgb(0, 0, 0);
}

.phLoader {
  margin: auto;
  /* width: 50px;
  height: 50px; */
}

.bg {
  position: absolute;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  cursor: pointer;
}

.textWrapper {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.title {
  margin-top: 25px;
  margin-left: 50px;
  z-index: 1;
  font-size: 1.1rem;
  color: rgb(255,255,255);
  flex: 1;
  font-weight: bold;
}

.description {
  text-align: right;
  margin-top: 220px;
  margin-right: 220px;
  font-size: 2rem;
}

.descr {
  font-weight: bold;
}

.descrSub {

}

.enterButton {
  z-index: 1;
  margin: auto;
  padding-bottom: 50px;
  height: 100px;
}

.enterButton img {
  height: 100%;
  cursor: pointer;
}

@media only screen and (max-device-width: 1280px) {
  .enterButton {
    height: 75px;
  }

  .title {
    font-size: 0.9rem;
  }
}
