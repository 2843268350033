.Logo {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.title {
  display: flex;
  flex-direction: row;
  font-size: 2rem;
  color: rgb(0, 0, 0);
  transform: translateY(-4px);
  font-weight: bold !important;
}

.Logo a {
  text-decoration: none;
  outline: 0;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  font-weight: bold;
}

/* .Logo a:hover {
  font-style: italic;
} */

.title1 {
  margin-right: 20px;
}

.title2 {

}

.title3 {
  margin-left: 20px
}

.subtitle {
  font-size: 2rem;
  color: rgb(0, 0, 0);
  margin-top: 2rem;
}

@media
only screen and (-webkit-min-device-pixel-ratio: 1.25),
only screen and ( min--moz-device-pixel-ratio: 1.25),
only screen and ( -o-min-device-pixel-ratio: 1.25/1),
only screen and ( min-device-pixel-ratio: 1.25),
only screen and ( min-resolution: 200dpi),
only screen and ( min-resolution: 1.25dppx) {
  .title {
    font-size: 1.8rem;
  }

  .subtitle {
    font-size: 1rem;
    margin-top: 1rem;
  }
}

@media only screen and (max-device-width: 319px) {
  .title1 {
    margin-right: 20px;
  }
}

@media only screen and (max-device-width: 767px) {
  .Logo a:hover {
    font-weight: 100;
  }

  .title {
    font-size: 3rem;
  }

  .title1 {
    margin-right: 50px;
  }

  .title2 {
    /* margin-right: 25%; */
  }

  .subtitle {
    display: none;
  }

  .title3 {
    display: none;
  }
}
