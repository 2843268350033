.socialmenu {
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 4px;
}

.wrapper {
  margin-top: auto;
  margin-left: auto;
}

.wrapper img {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.5rem;
}

@media only screen and (max-device-width: 767px) {
  .socialmenu{
    /* display: none; */
  }
}
