.Text {
  width: 320px;
  font-weight: 300;
  color: rgb(0, 0, 0);
  font-size: 1rem;
}

.Text table {
  width: 320px;
  border-spacing: 0;
  /* font-size: 1rem; */
}

.titleWrapper{
  display: flex;
  flex-direction: row;
}

.title {
  font-weight: 100;
  /* font-size: 1rem; */
  /* color: rgb(80, 0, 76); */
  font-weight: bold;
  margin-right: 10px;
}

.arrow img{
  width: 30px;
}

.textItemTitle {
  width: 33%;
  vertical-align: top;
  /* font-weight: 100; */
}

.textItem {
  text-align: left;
  font-weight: 400;
  /* font-weight: bold; */
  /* color: rgb(80, 0, 76) */
}

@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation: portrait) {
  .Text {
    width: 240px;
  }

  .Text table {
    width: 240px;
  }
}

@media
only screen and (-webkit-min-device-pixel-ratio: 1.25),
only screen and ( min--moz-device-pixel-ratio: 1.25),
only screen and ( -o-min-device-pixel-ratio: 1.25/1),
only screen and ( min-device-pixel-ratio: 1.25),
only screen and ( min-resolution: 200dpi),
only screen and ( min-resolution: 1.25dppx) {
  .Text {
    font-size: 0.8rem;
  }
}
