.Navigation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.nextBtn {
  margin: auto auto 25px;
}

.nextBtn img {
  width: 100%;
  height: auto;
}

.nextBtn:hover {
  cursor: pointer;
}
