.process {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.items {
  height: 100%;
  width: 100%;
}

.swipe {
  height: 100%;
  width: 100%;
}
