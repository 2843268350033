.contact {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.items {
  height: 100%;
  width: 100%;
}

@media only screen and (max-device-width: 767px) {
  .items {
    display: flex;
    flex-direction: column;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
