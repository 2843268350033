.news {
  height: 100%;
  width: 100%;
}

.items {
  height: 100%;
  width: 100%;
  /* margin-top: 100px; */
}

@media only screen and (max-device-width: 767px) {
  .news {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .items {
    margin-top: 100px;
  }
}
