.ProjectOverview {
  height: 100%;
  width: 1020px;
  margin: auto;
}

.wrapper {
  margin-top: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  align-content: flex-start;
}

@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation: portrait) {
  .ProjectOverview {
    width: 520px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .wrapper {
    margin-top: 100px;
  }
}

@media only screen and (max-device-width: 767px) {
  .ProjectOverview {
    width: 320px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .wrapper {
    margin-top: 100px;
  }
}
