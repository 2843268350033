.projects {
  height: 100%;
  width: 100%;
  overflow: hidden;
  color: rgb(0, 0, 0);
}

.items {
  height: 100%;
  width: 100%;
}

.swipe {
  height: 100%;
  width: 100%;
}

@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation: portrait){
  .items {
    /* position: relative; */

  }
}

@media only screen and (max-device-width: 767px) {
  .projects {

  }

  .items {
    position: absolute;
    top: 50%;
  }
}
