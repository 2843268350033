.menu {
  /* height: 100%; */
  background-color: transparent;
  /* margin-top: 2rem; */
  display: flex;
  flex-direction: column;
}

.header {
  margin-left: auto;
  margin-right: auto;
  /* width: 340px; */
  width: 100%;
  display: flex;
  flex-direction: row;
}

.title {
  flex: 1;
  color: #fff;
  font-size: 6rem;
  font-weight: bold;
  /* transform: translateY(-1rem) translateX(-0.3rem); */
}

/* .link {
  margin-top: auto;
  font-size: 1.03rem;
  height: 1rem;
}

.link img {
  height: 0.75rem;
  width: auto;
}

.hamburger a {
  color: rgb(50, 50, 50);
  text-decoration: none;
  border: 0;
  padding: 0;
  margin: 0;
  font-weight: bold;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
}

.hamburger a:active,
.hamburger a:hover{
  font-weight: bold;
}

.hamburger a:focus {
  outline: 0;
} */

/* .content {
  flex: 1;
  margin-top: 2rem;
  margin-bottom: 2rem;
} */
