.Item {
  position: relative;
  width: 320px;
  /* height: 200px; */
  margin: 10px 10px 30px;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.wrapper a{
  color: rgb(50, 50, 50);
  text-decoration: none;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  outline: 0;
}

.textWrapper {
  /* position: absolute;
  top: 0;
  left: 0; */
  margin-top: 10px;
  display: flex;
  width: 100%;
  height: 100%;
}

.text {
  width: 100%;
  margin: auto;
  /* opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  -o-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease; */
  /* z-index: 100; */
}

.image {
  /* -webkit-filter: grayscale(0%) blur(0);
  -moz-filter: grayscale(0%) blur(0);
  -o-filter: grayscale(0%) blur(0);
  -ms-filter: grayscale(0%) blur(0);
  filter: grayscale(0%) blur(0);
  opacity: 1;

  -webkit-transition-property: opacity, filter;
  -webkit-transition-duration: 0.25s;
  -webkit-transition-timing-function: ease;

  -moz-transition-property: opacity, filter;
  -moz-transition-duration: 0.25s;
  -moz-transition-timing-function: ease;

  -o-transition-property: opacity, filter;
  -o-transition-duration: 0.25s;
  -o-transition-timing-function: ease;

  transition-property: opacity, filter;
  transition-duration: 0.25s;
  transition-timing-function: ease; */
}

/* @media
only screen and (-webkit-min-device-pixel-ratio: 1.25),
only screen and ( min--moz-device-pixel-ratio: 1.25),
only screen and ( -o-min-device-pixel-ratio: 1.25/1),
only screen and ( min-device-pixel-ratio: 1.25),
only screen and ( min-resolution: 200dpi),
only screen and ( min-resolution: 1.25dppx) {
  .Item {
    width: 240px;
  }
} */

@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation: portrait) {
  .Item {
    width: 240px;
  }
}

@media only screen and (max-device-width: 767px) {
  .Item {
    /* position: relative; */
    width: 320px;
    /* height: 300px; */
    margin: 0 0 30px;;
  }

  .image {
    /* height: 300px; */
  }

  .textWrapper {
    margin-top: 5px;
  }
}
