.newsItem {
  display: flex;
  flex-direction: column;
  color: rgb(0, 0, 0);
  margin-bottom: 1rem;
  font-size: 1rem;
}

.wrapper {
  padding-top: 2rem;
  margin: auto;
}

.title {
  margin-bottom: 0.5rem;
  /* font-size: 1rem; */
  font-weight: bold;
  /* color: rgb(80, 0, 76); */
}

.text {
  /* font-size: 1rem; */
  margin: 0 auto;
  font-weight: 300;
}

.text a {
  /* color: rgb(1, 3, 89); */
}

.text p {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.image {
  width: 500px;
  /* height: 200px; */
}

.landscape img {
  width: 100%;
  /* max-width: 400px; */
}

.portrait img {
  height: 100%;
  width: auto;
  /* max-height: 400px; */
}

.textWrapper {
  /* margin-left: 2rem; */
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  width: 500px;
}


.content {
  display: flex;
  flex-direction: column;
}

.date {
  /* font-size: 1rem; */
}

/* @media only screen and (max-device-width: 1280px) {
  .image {
    width: 150px;
    height: 150px;
  }

  .textWrapper {
    width: 400px;
  }

  .text {
    font-size: 0.70rem;
  }

  .title {
    font-size: 0.8rem;
  }

  .date {
    font-size: 0.8rem;
  }
} */

@media
only screen and (-webkit-min-device-pixel-ratio: 1.25),
only screen and ( min--moz-device-pixel-ratio: 1.25),
only screen and ( -o-min-device-pixel-ratio: 1.25/1),
only screen and ( min-device-pixel-ratio: 1.25),
only screen and ( min-resolution: 200dpi),
only screen and ( min-resolution: 1.25dppx) {
  .newsItem {
    font-size: 0.8rem;
  }
}

@media only screen and (max-device-width: 767px) {
  .wrapper {
    padding-top: 0;
    padding-bottom: 2rem;
  }

  .content {
    flex-direction: column;
  }

  .image {
    width: 300px;
    height: auto;
    margin: auto;
  }

  .textWrapper {
    margin-left: 0;
    width: 300px;
  }
}
