.menu {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.menuAnim {
  -webkit-transition: transform 0.5s ease-out;
  -moz-transition: transform 0.5s ease-out;
  -o-transition: transform 0.5s ease-out;
  transition: transform 0.5s ease-out;
}

.menuWrapper {
  position: fixed;
  /* top: -20px; */
  /* left:10%; */
  display: flex;
  flex-direction: column;
}

.menuItems {
  /* margin-top: 32px; */
  /* margin-top: 2rem; */
  margin-left: 4px;
  /* margin-top: 4px; */
}

.logo {
  /* font-size: 140px; */
  font-weight: normal;
  /* margin-right: 5px; */
  margin-left: 4px;
  margin-top: 4px;
}

/* @media only screen and (max-device-width: 767px) {
  .socialmenu{
    display: none;
  }
} */

@media only screen and (min-device-width: 767px) {
  .mobileMenu {
    display: none;
  }
}

@media only screen and (max-device-width: 767px) {
  .menu {
    flex-direction: column;
  }

  .mobileMenu {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 500;
    /* height: 100% */
    /* background-color: rgb(220, 220, 220); */
  }

  .menuWrapper {
    display: none;
  }

  .social{
    display: none;
  }

  .lang {
    display: none;
  }
}
