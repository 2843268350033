.cv {
  height: 100%;
  width: 100%;
  /* overflow: visible; */
}

.items {
  height: 100%;
  width: 100%;
}

@media only screen and (max-device-width: 767px) {
  .cv {
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
