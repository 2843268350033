html, body {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(255, 255, 255);
  color: rgb(50, 50, 50);
  height: 100%;
  font-size: 1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (max-device-width: 767px) {
  html, body {
    /* position: relative; */
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    width: 100%;
  }
}
