.Navigation {
  height: 600px;
  width: 1500px;
  /* transform: translateX(-300px); */
  /* position: absolute;
  top: 0;
  left: 0; */
  display: flex;
  flex-direction: row;
}

.swipe {
  position: absolute;
  top: 0;
  left: 0;
  height: 600px;
  width: 1500px;
  transform: translateX(-300px);
  -webkit-transform: translateX(-300px);
  -moz-transform: translateX(-300px);
  -o-transform: translateX(-300px);
  -ms-transform: translateX(-300px);
}

.btnFull {
  height: 100%;
  width: 100%;
}

.btn {
  height: 100%;
  width: 50%;
}

@media
only screen and (-webkit-min-device-pixel-ratio: 1.25) and (max-device-width: 2560px),
only screen and ( min--moz-device-pixel-ratio: 1.25) and (max-device-width: 2560px),
only screen and ( -o-min-device-pixel-ratio: 1.25/1) and (max-device-width: 2560px),
only screen and ( min-device-pixel-ratio: 1.25) and (max-device-width: 2560px),
only screen and ( min-resolution: 200dpi) and (max-device-width: 2560px),
only screen and ( min-resolution: 1.25dppx) and (max-device-width: 2560px) {
  .Navigation {
    height: 466px;
    width: 1100px;
    transform: translateX(-200px);
  }

  .swipe {
    position: absolute;
    top: 0;
    left: 0;
    height: 466px;
    width: 1100px;
    transform: translateX(0);
  }
}

/* @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (max-device-width: 1024px),
only screen and ( min--moz-device-pixel-ratio: 1.25) and (max-device-width: 1024px),
only screen and ( -o-min-device-pixel-ratio: 1.25/1) and (max-device-width: 1024px),
only screen and ( min-device-pixel-ratio: 1.25) and (max-device-width: 1024px),
only screen and ( min-resolution: 200dpi) and (max-device-width: 1024px),
only screen and ( min-resolution: 1.25dppx) and (max-device-width: 1024px) {
  .Navigation {
    height: 266px;
    width: 600px;
    transform: translateX(-100px);
  }

  .swipe {
    position: absolute;
    top: 0;
    left: 0;
    height: 266px;
    width: 600px;
    transform: translateX(0);
  }
} */

@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation: portrait) {
  .Navigation {
    width: 600px;
    height: 266px;
  }

  .swipe {
    height: 266px;
    width: 600px;
    transform: translateX(-100px);
  }
}

@media only screen and (max-device-width: 767px) {
  .Navigation {
    height: 213px;
    width: 100%;
    transform: translateX(0);
  }

  .swipe{
    /* position: absolute;
    top: 0;
    left: 0; */
    height: 213px;
    width: 320px;
    display: inherit;
    transform: translateX(0);
  }
}
