.menuItem {
  /* line-height: 2rem; */
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -o-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
  -webkit-transition-property: transform;
  -webkit-transition-duration: 1.0s;
  -webkit-transition-timing-function: ease;

  -moz-transition-property: transform;
  -moz-transition-duration: 1.0s;
  -moz-transition-timing-function: ease;

  -o-transition-property: transform;
  -o-transition-duration: 1.0s;
  -o-transition-timing-function: ease;

  transition-property: transform;
  transition-duration: 1.0s;
  transition-timing-function: ease;
  margin-bottom: 0.5rem;
}

.menuItem a {
  /* color: rgb(50, 50, 50); */
  text-decoration: none;
  font-size: 2rem;
  /* font-weight: bold; */
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  outline: 0;
  font-weight: bold;
}

.menuItem a:active,
.menuItem a:hover{
  font-style: italic;
}

/* .contact {
  border-top: 1px solid rgb(50, 50, 50);
  margin-top: 0.4rem;
  padding-top: 0.35rem;
} */

.active a {
  /* font-weight: bold; */
}

.active {
  /* font-weight: bold; */
  /* text-decoration: line-through; */
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

@media
only screen and (-webkit-min-device-pixel-ratio: 1.25),
only screen and ( min--moz-device-pixel-ratio: 1.25),
only screen and ( -o-min-device-pixel-ratio: 1.25/1),
only screen and ( min-device-pixel-ratio: 1.25),
only screen and ( min-resolution: 200dpi),
only screen and ( min-resolution: 1.25dppx) {
  .menuItem a {
    font-size: 1.8rem;
  }
}

@media only screen and (max-device-width: 767px){
  .menuItem {
    margin-left: auto;
    line-height: 2rem;
  }

  .menuItem a {
    /* color: rgb(50, 50, 50); */
    text-decoration: none;
    font-size: 2rem;
    font-weight: 100;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
    outline: 0;
    font-weight: bold;
  }

  .active {
    /* font-weight: bold; */
    /* text-decoration: line-through; */
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -o-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
  }
}

/* @media only screen and (min-device-width: 767px) and (max-device-width: 812px){
  .menuItem a {
    font-size:  0.9rem;
  }
}

@media only screen and (min-device-width: 812px) and (max-device-width: 1280px) {
  .menuItem a {
    font-size: 0.65rem;
  }

  .menuItem {
    line-height: 0.75rem;
  }

  .contact {
    margin-top: 0.3rem;
    padding-top: 0.25rem;
  }
} */
