.MenuItems {
  display: flex;
  flex-direction: column;
}

.divider {
  height: 4px;
  display: flex;
  flex-direction: row;
  /* margin-top: 2rem; */
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  width: 50px;
}

.blue {
  background-color: rgb(0, 0, 0);
}

.purple {
  background-color: rgb(125, 0, 118);
}


.left {
  /* background-color: rgb(50, 50, 50); */
  width: 200px;
}

.right {
  background-color: transparent;
  width: 0;
}

@media
only screen and (-webkit-min-device-pixel-ratio: 1.25),
only screen and ( min--moz-device-pixel-ratio: 1.25),
only screen and ( -o-min-device-pixel-ratio: 1.25/1),
only screen and ( min-device-pixel-ratio: 1.25),
only screen and ( min-resolution: 200dpi),
only screen and ( min-resolution: 1.25dppx) {
  .left{
    width: 130px;
  }
}

@media only screen and (max-device-width: 767px) {
  .divider {
    height: 5px;
    margin-left: auto
  }

  .left {
    background-color: transparent;
    width: 100%;
  }

  .right {
    /* background-color: rgb(50, 50, 50); */
    width: 0%;
  }
}
