.processItem {
  height: 100%;
  display: flex;
  flex-direction: column;
  -webkit-transition: transform 1.0s ease-in-out;
  -moz-transition: transform 1.0s ease-in-out;
  -o-transition: transform 1.0s ease-in-out;
  transition: transform 1.0s ease-in-out;
}

.wrapper {
  margin: auto;
}

.content {
  width: 600px;
  height: 600px;
}

.title {
  text-align: center;
  margin-top: 1rem;
  font-size: 0.77rem;
  color: rgb(1, 3, 89);
}

.text {
  font-size: 1rem;
  width: 50%;
  margin: 0 auto
}

.image {
  width: 600px;
  height: 600px;
  display: flex;
  flex-direction: column;
}

.image img{
  margin: auto;
}

.landscape img {
  width: 100%;
  max-width: 600px;
}

.portrait img {
  height: 100%;
  width: auto;
  max-height: 600px;
}

/* @media only screen and (max-device-width: 1280px) {
  .image {
    width: 400px;
    height: 400px;
  }

  .content {
    width: 400px;
    height: 400px;
  }
} */

@media
only screen and (-webkit-min-device-pixel-ratio: 1.25),
only screen and ( min--moz-device-pixel-ratio: 1.25),
only screen and ( -o-min-device-pixel-ratio: 1.25/1),
only screen and ( min-device-pixel-ratio: 1.25),
only screen and ( min-resolution: 200dpi),
only screen and ( min-resolution: 1.25dppx) {
  .content {
    width: 400px;
    height: 400px;
  }

  .image {
    width: 400px;
    height: 400px;
  }
}

/* @media
only screen and (orientation: landscape),
only screen and (-webkit-min-device-pixel-ratio: 1.25),
only screen and ( min--moz-device-pixel-ratio: 1.25),
only screen and ( -o-min-device-pixel-ratio: 1.25/1),
only screen and ( min-device-pixel-ratio: 1.25),
only screen and ( min-resolution: 200dpi),
only screen and ( min-resolution: 1.25dppx)
{
  .content {
    width: 400px;
    height: 400px;
  }

  .image {
    width: 400px;
    height: 400px;
  }
} */

@media only screen and (max-device-width: 767px) {
  .content {
    width: 300px;
    height: 300px;
  }

  .image {
    width: 300px;
    height: 300px;
  }
}
