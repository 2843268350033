.language {
  position: fixed;
  right: 0;
  text-align: right;
  font-size: 2rem;
  color: rgb(0, 0, 0);
  margin-left: 0;
  margin-right: 4px;
}

.language a {
  border: 0;
  padding: 0;
  margin: 0;
  background-color: transparent;
  min-width: 20px;
  /* font-size: 1.5rem; */
  text-decoration: none;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  outline: 0;
  font-weight: bold;
}

.language a:hover{
  cursor: pointer;
  font-style: italic;
}

.language a:focus {
  outline: 0;
}

.language a::-moz-focus-inner {
  border: 0;
}

/* .btn {
  font-weight: 100;
} */

/* .nl, .en {

}

.notActive {
  color: rgb(50, 50, 50);
}

.active {
  color: rgb(100, 15, 15);
} */

@media
only screen and (-webkit-min-device-pixel-ratio: 1.25),
only screen and ( min--moz-device-pixel-ratio: 1.25),
only screen and ( -o-min-device-pixel-ratio: 1.25/1),
only screen and ( min-device-pixel-ratio: 1.25),
only screen and ( min-resolution: 200dpi),
only screen and ( min-resolution: 1.25dppx) {
  .language {
    font-size: 1.8rem;
  }
}

@media only screen and (max-device-width: 767px) {
  .language{
    /* display: none; */
    left: 0;
    bottom: 0;
    right: auto;
    margin-left: 4px;
    margin-right: 0;
    font-size: 1.7rem;
  }

  .language a:hover{
    font-weight: 100;
  }
}

/* @media only screen and (min-device-width: 767px) and (max-device-width: 812px){
  .language {
    font-size: 1.0rem;
  }

  .language button {
    font-size: 1.0rem;
  }
} */
