.app {
  position: relative;
}

.contentWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  /* filter: blur(4px); */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}
